export const Prices=[
    {
        _id:0,
        name:'Rs 0 to 99',
        array:[0,99]
    },
    {
        _id:1,
        name:'Rs 100 to 1999',
        array:[100,1999]
    },
    {
        _id:2,
        name:'Rs 2000 to 2999',
        array:[2000,2999]
    },
    {
        _id:3,
        name:'Rs 3000 to 4999',
        array:[3000,4999]
    },
    {
        _id:4,
        name:'5000 or More',
        array:[5000,9999]
    }
]